.area {
  background: #0a3447;
  background: -webkit-linear-gradient(#25313b, #141414);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -100;
  top: 0;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0);
  animation: animate 25s linear infinite;
  bottom: -150px;
}
.innerImage {
  width: 100%;
}
.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 40px;
  height: 40px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 40px;
  height: 40px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 120px;
  height: 120px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 35px;
  height: 35px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 45px;
  height: 45px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 110px;
  height: 110px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(120deg);
    opacity: 0;
    border-radius: 50%;
  }
}
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.main-container {
  display: flex;
  flex-direction: column;
  align-content: center;
}
