.project-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
.project-item img {
  width: 90px;
  height: 90px;
  /* filter: grayscale(38%) sepia(100%) brightness(88%) hue-rotate(153deg)
    saturate(63%) drop-shadow(5px 5px 7px #000000);
  -webkit-filter: grayscale(38%) sepia(100%) brightness(88%) hue-rotate(153deg)
    saturate(63%) drop-shadow(5px 5px 7px #000000);
  -moz-filter: grayscale(38%) sepia(100%) brightness(88%) hue-rotate(153deg)
    saturate(63%) drop-shadow(5px 5px 7px #000000); */
  margin-bottom: 10px;
  border-radius: 20px;
  flex-grow: 1;
}
.project-item-title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.2em;
  color: rgb(255, 255, 255);
  flex-grow: 0;
}
.project-item-desc::-webkit-scrollbar {
  display: none;
}
.project-item-desc {
  width: 100%;
  text-align: justify;
  font-size: 1.1em;
  color: rgb(187, 186, 186);
  overflow: scroll; /* Hide scrollbars */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  flex-grow: 10;
}

.project-item-stack {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgb(211, 209, 209);
  bottom: 0;
  flex-grow: 1;
}
.project-item-stackitem {
  margin-left: 10px;
  margin-right: 10px;
}

.Projects-section-title {
  margin-top: 20px;
  align-self: center;
  text-align: center;
  width: 100%;
  font-size: 2em;
  background-color: rgba(255, 255, 255, 0.144);
  color: rgb(255, 255, 255);
  padding: 15px;
}
