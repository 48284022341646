.header-bg {
  background-color: #46464670;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: rgba(255, 255, 255, 0.79);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 60px;
  z-index: 100000;
  transition: top 0.6s ease-in-out;
  border-bottom-style: solid;
  border-bottom-width: 5px;
  border-color: rgba(129, 129, 129, 0.523);
}

@media screen and (orientation: portrait) {
  .header-bg {
    height: 7vh;
  }
}

.hide-header {
  top: -100px;
}