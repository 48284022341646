@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap");
* {
  margin: 0px;
  font-family: "Lexend Deca", sans-serif;
  padding: 0px;
}

.app {
  color: whitesmoke;
}
