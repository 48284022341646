.gallery-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  /* margin-top: 20px; */
}

.gallery-item img {
  width: 90px;
  height: 90px;
  /* filter: grayscale(38%) sepia(100%) brightness(88%) hue-rotate(153deg)
    saturate(63%) drop-shadow(5px 5px 7px #000000);
  -webkit-filter: grayscale(38%) sepia(100%) brightness(88%) hue-rotate(153deg)
    saturate(63%) drop-shadow(5px 5px 7px #000000);
  -moz-filter: grayscale(38%) sepia(100%) brightness(88%) hue-rotate(153deg)
    saturate(63%) drop-shadow(5px 5px 7px #000000); */
  margin-bottom: 10px;
  border-radius: 20px;
  flex-grow: 1;
}

.gallery-item-title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.4em;
  color: rgb(255, 255, 255);
  flex-grow: 0;
}

.gallery-item-divider{
  height: 5px;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.144);
  border-radius: 5px;
  margin: 20px;
   
}
.video-responsive {
  aspect-ratio: 16 / 9;
  width: 60%;
}



.h-divider {
  margin: auto;
  margin-top: 80px;
  width: 80%;
  position: relative;
}

.h-divider .shadow {
  overflow: hidden;
  height: 20px;
}

.h-divider .shadow:after {
  content: '';
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px/12px;
  box-shadow: 0 0 8px black;
}

.h-divider .text {
  width: 100px;
  height: 45px;
  padding: 10px;
  position: absolute;
  bottom: 100%;
  margin-bottom: -33px;
  left: 50%;
  margin-left: -60px;
  border-radius: 100%;
  box-shadow: 0 2px 4px #999;
  background: white;
}

.h-divider .text i {
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  border-radius: 100%;
  border: 1px dashed #aaa;
  text-align: center;
  line-height: 50px;
  font-style: normal;
  color: #999;
}

.h-divider .text2 {
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 100%;
  margin-bottom: -35px;
  left: 50%;
  margin-left: -25px;
  border-radius: 100%;
  box-shadow: 0 2px 4px #999;
  background: white;
}

.h-divider img {
  position: absolute;
  margin: 4px;
  max-width: 60px;
  border-radius: 100%;
  border: 1px dashed #aaa;
}



.gallery-item-desc::-webkit-scrollbar {
  display: none;
}

.gallery-item-desc {
  width: 100%;
  text-align: center;
  font-size: 1.1em;
  color: rgb(187, 186, 186);
  overflow: scroll;
  /* Hide scrollbars */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  flex-grow: 10;
  margin-bottom: 30px;
}

.gallery-item-stack {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgb(211, 209, 209);
  bottom: 0;
  flex-grow: 1;
}

.gallery-item-stackitem {
  margin-left: 10px;
  margin-right: 10px;
}

.gallery-section-title {
  margin-top: 20px;
  margin-bottom: 40px;
  align-self: center;
  text-align: center;
  width: 100%;
  font-size: 2em;
  background-color: rgba(255, 255, 255, 0.144);
  color: rgb(255, 255, 255);
  padding: 15px;
}