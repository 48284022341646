.about-me-bg {
  margin-top: 4em;
  margin-left: 3em;

  margin-right: 3em;
  /* border-color: rgba(255, 255, 255, 0.801);
  border-style: solid;
  border-radius: 0.3em; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
}

.about-me-wellcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
}
.about-me-desc {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0.5em;
  text-align: center;
}

.hello-there {
  font-size: 2em;
  margin: 0.5em;

  /* margin-bottom: 0.6em; */
}

.intro {
  font-size: 1.6em;
  margin-bottom: 0.5em;
}
.general-desc {
  font-size: 1.2em;  
  margin: 0.1em;

}

.social-container {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}
.social-item {
  margin: 10px;
  
  height: 36px;
  width: 36px;
  transition: transform 0.2s;
}
.social-item:hover {
  color: #ffffff;
  transform: scale(1.2);
}
@media screen and (orientation: portrait) {
  .social-item {
    height: 26px;
    width: 26px;
    margin: 7.5px;
  }
  .hello-there {
    font-size: 2em;
    margin-bottom: 0.6em;
    margin-top: 0.3em;
  }

  .intro {
    font-size: 1.4em;
  }
  .general-desc {
    font-size: 1.1em;
  }
}

.downloadable-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.downloadable-item {
  margin: 1.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 200px;
  /* border-style: solid; */
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  color: rgb(92, 92, 92);
  font-weight: bold;
  transition: 0.2s;
  box-shadow: 0 10px 10px rgba(53, 52, 52, 0.418);
}
.downloadable-item:hover {
  color: #686969;
  transform: scale(1.1);
}
.downloadable-item-icon {
  flex-grow: 20;
  text-align: center;
}

.downloadable-item-text {
  flex-grow: 80;
  text-align: center;
}
