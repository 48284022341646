.nav-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: transform 0.2s;
  cursor: pointer;
  margin: 10px;
  font-size: small;
}

.nav-button:hover {
  color: #555555;
  transform: scale(1.1);
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.3em;
}